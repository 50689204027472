<template>
    <canvas class="canvas" id="can"/>
</template>

<script>

/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */

"use strict";

const { PI, cos, sin, abs, sqrt, pow, floor, round, random, atan2 } = Math;
const HALF_PI = 0.5 * PI;
const TAU = 2 * PI;
const TO_RAD = PI / 180;
const rand = n => n * random();
const randIn = (min, max) => rand(max - min) + min;
const randRange = n => n - rand(2 * n);
const fadeIn = (t, m) => t / m;
const fadeOut = (t, m) => (m - t) / m;
const fadeInOut = (t, m) => {
	let hm = 0.5 * m;
	return abs((t + hm) % m - hm) / (hm);
};
const dist = (x1, y1, x2, y2) => sqrt(pow(x2 - x1, 2) + pow(y2 - y1, 2));
const angle = (x1, y1, x2, y2) => atan2(y2 - y1, x2 - x1);
const lerp = (n1, n2, speed) => (1 - speed) * n1 + speed * n2;

const particleCount = 1000;

let canvas;
let ctx;
let center;
let particles;
let firePos;
let hover;
let moveUp;

let isBurning = false;

Array.prototype.lerp = function(target, speed) {
	this.forEach((n, i) => (this[i] = lerp(n, target[i], speed)));
};

class Particle {
	constructor() {
		this.init();
	}
	get color() {
		return `hsla(${this.hue}, 50%, 50%, ${fadeInOut(this.life, this.ttl)})`;
	}
	init() {
		this.life = 0;
		this.ttl = randIn(20, 30);
		this.speed = randIn(3, 5);
		this.size = randIn(15, 25);
		this.position = [center[0] + randIn(-300,300), center[1] + randIn(-10,-30)];
		let direction = angle(center[0], center[1], this.position[0], this.position[1]);
		//randIn(-0.15 * PI, -0.85 * PI);
		this.velocity = [
			cos(direction) * this.speed,
			sin(direction) * this.speed * 2
		];
		this.hue = randIn(200,240);
		this.reset = false;
	}
	checkBounds() {
		const [x, y] = this.position;
		return x > canvas.b.width || x < 0 || y > canvas.b.height || y < 0;
	}
	update() {
		this.speed = fadeIn(dist(center[0], center[1], this.position[0], this.position[1]), 0.5 * canvas.b.height) * 20;
		this.velocity[0] = lerp(this.velocity[0], cos(-HALF_PI) * this.speed, 0.1);
		this.velocity[1] *= 1.01;
		this.position[0] += this.velocity[0];
		this.position[1] += this.velocity[1];

		(this.checkBounds() || this.life++ > this.ttl) && this.init();

		return this;
	}
	draw() {

		if(moveUp > 750) {
			this.size--;
			
			if(this.size < 0) this.size = 0;

		}

		ctx.b.save();
		ctx.b.fillStyle = this.color;
		ctx.b.fillRect(this.position[0], this.position[1], this.size, this.size * 1.5);
		ctx.b.restore();

		return this;
	}
}

function setup() {
	createCanvas();
	createParticles();
	draw();
	resize();
}

let anim;

function startAnimation() {
    moveUp = 0;

    anim = setInterval(() => {
        moveUp += 8;

        if(moveUp > 780) {
            clearInterval(anim);
			moveUp = 0;
			setup();
			isBurning = false;
			return;
		}
    }, 100);
}

function createCanvas() {
    let can = document.getElementById('can');

	canvas = {
		b: can
	};
    

	canvas.b.style = `
		position: relative;
		width: 100%;
		height: 100%;
		filter: contrast(1.5);
        z-index: -1;
	`;

	ctx = {
		b: canvas.b.getContext("2d")
	};

	center = [0,0];
	firePos = [0,0];
	hover = false;
}

function createParticles() {
	particles = [];

	let i;

	for (i = 0; i < particleCount; i++) {
		particles.push(new Particle());
	}
}

function resize() {
	const { innerWidth, innerHeight } = window;

	canvas.b.width = 1021;
	canvas.b.height = 1287;


	let difference = moveUp ? moveUp : 0;


	center[0] = 0.5 * canvas.b.width;
	center[1] = canvas.b.height * 0.80;

    if(moveUp)
        center[1] -= moveUp

}

function renderToScreen() {
	ctx.b.save();
	ctx.b.filter = "blur(15px)";
	ctx.b.drawImage(canvas.b, 0, 0);
	ctx.b.restore();
	
	ctx.b.save();
	ctx.b.filter = "blur(18px)";
	ctx.b.globalCompositeOperation = 'soft-light';
	ctx.b.drawImage(canvas.b, 0, 0);
	ctx.b.restore();
	
	ctx.b.save();
	ctx.b.filter = "blur(6px)";
	ctx.b.globalCompositeOperation = 'lighter';
	ctx.b.drawImage(canvas.b, 0, 0);
	ctx.b.restore();
}

function draw() {
	ctx.b.clearRect(0, 0, canvas.b.width, canvas.b.height);

	if(!isBurning || (center[0] === 0 || center[1] === 0)) {
		return;
	}


    let pos = [];

    pos[0] = 0.5 * canvas.b.width;
    pos[1] = canvas.b.height - moveUp - 240;

	center.lerp([pos[0], pos[1]], 0.1);

	let i;

	for (i = 0; i < particleCount; i++) {
		particles[i].draw().update();
	}

	renderToScreen();

	window.requestAnimationFrame(draw);
}

window.addEventListener("load", setup);
window.addEventListener("resize", resize);



export default {
  props: ['burning', 'idBurning'],
  data() {
    return {

    };
  },
  methods: {
    startFire(at) {
		clearInterval(anim);
		isBurning = true;
		startAnimation();
		draw();
		moveUp = at;
	}
  },
  computed: {
    
  },
mounted() {

  },
}
</script>

<style lang="scss" scoped>

.canvas {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 92%;
    width: 92%;
}

</style>
