<template>
    <div class="main-container">

        <ButtonType
            :text="'Back'"
            :type="'med'"
            :size="1.5"
            :style="{'margin' : '10px'}"
            @click="$emit('back')"
        />
        
        {{burnsLeft}} Souls To Burn

    <div class="b-container">
        <BurnArea
            ref="burn"
            :burning="burning"
            :idBurning="idBurning"
            :burningWallet="burningWallet"
            @decrementToBurn="$emit('decrementToBurn')"
        />

        <BurnQueue
            :nextBurn="nextBurn"
        />
    </div>

    <div class="warning-container">
        <div class="warning-text">
            <img class="warning-back" src="../assets/U_skill_bar.png"/>

            <label class="burn-warning">Warning!</label>
            <label>If you burn your nft you will lose it forever</label>
        </div>
    </div>

   
    

    <Pilgrims
        :pilgrims="pilgrims"
        @burn="burnNFT"
    />

    </div>
</template>

<script>

import BurnArea from './BurnArea.vue';
import BurnQueue from './BurnQueue.vue';
import ButtonType from './ButtonType.vue';
import Pilgrims from './Pilgrims.vue';

export default {
  props: ['burning', 'idBurning', 'nextBurn', 'burnsLeft', 'burningWallet', 'pilgrims'],
  components: {
      BurnArea,
      BurnQueue,
      ButtonType,
      Pilgrims,
  },
  data() {
    return {
      
    };
  },
  methods: {
    burnNFT(id) {
        this.$emit('burn', id);
    },
    startBurn() {
        this.$refs.burn.startBurning(0);
    }
  },
  computed: {
    
  },
};
</script>

<style scoped>

.warning-text {
    position: relative;
    display: flex;
    width: 75%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.warning-back {
    position: absolute;
    width: 100%;
    height: 120%;
    z-index: -1;
    padding-top: 20px;
    filter: hue-rotate(150deg);
}

.warning-container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 692px;
    min-width: 521px;
    align-items: center;
}

.burn-warning {
    margin-top: 20px;
    text-shadow: 1px 1px black;
    color: red;
}

.b-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.main-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    font-size: 30px;
    user-select: none;
}

</style>
