<template>

  <WelcomeScreen
    v-show="!entered && !signDiscord"
    @enter="enter"
  />

  <div class="connection-issue" v-if="entered && !connected && !signDiscord">
    Please connect your wallet
  </div>

  <div class="connection-issue" v-if="entered && connected && !correctChain && !signDiscord">
    Please switch to ethereum mainnet
  </div>

  <div v-show="entered && connected && correctChain && !signDiscord">
    <NavBar
      :wallet="wallet"
    />

    <SelectionMenu
      v-if="page === 'main'"
      @switchTo="switchPage"
    />

    <BurnPage
      v-show="page === 'burns'"
      ref="bpage"
      :burning="burning"
      :idBurning="idBurning"
      :nextBurn="nextBurn"
      :burnsLeft="burnsLeft"
      :burningWallet="burningWallet"
      @back="switchPage('main')"
      @decrementToBurn="decrementBurn"

      :pilgrims="pilgrims"
      @burn="burnNFT"

    />

    <SoulsPage
      v-show="page === 'souls'"
      :burning="burning"
      :burntSouls="burntSouls"
      @back="switchPage('main')"
      @mintSoul="claimSoul"
      :loadedSouls="loadedSouls"

    />

    <LeaderboardPage
      v-show="page === 'leaderboard'"

      @back="switchPage('main')"
      :souls="souls"
      :page="pageNum"
      :total="total"
      @changePage="changePage"
      :burners="burners"
    />

    <ShopPage
      v-if="page === 'shop'"
      :shopItems="shopItems"

      @back="switchPage('main')"
      @buy="buyItem"

      :shopOpen="shopOpen"
      :txStage="txStage"
      @resetStage="txStage = 'none'"
    />

    <DicePage
      v-if="page === 'dice'"
      @back="switchPage('main')"

    />

    <QuestCreator
      v-if="page === 'questcreator'"
      @back="switchPage('main')"

    />

    <DiscordAuth
      v-if="page === 'discord'"

    />

  </div>

  <div v-if="signDiscord" class="main-discord-sign-container">

    <label id="signmessage" class="sign-message-title">Metamask will prompt you to sign a harmless message used only by our discord bot to link your wallet to your discord id</label>

    <ButtonType
      :text="'Sign Message'"
      :type="'long'"
      :size="2"
      :style="{'margin' : '10px'}"
      @click="authDiscord"
    />

  </div>

</template>



<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */




import NavBar from './components/NavBar.vue';
import WelcomeScreen from './components/WelcomeScreen.vue';
import SelectionMenu from './components/SelectionMenu.vue';
import BurnPage from './components/BurnPage.vue';
import SoulsPage from './components/SoulsPage.vue';
import LeaderboardPage from './components/LeaderboardPage.vue';
import ShopPage from './components/ShopPage.vue';
import DicePage from './components/DicePage.vue';
import QuestCreator from './components/QuestCreator.vue';
import DiscordAuth from './components/DiscordAuth.vue';
import ButtonType from './components/ButtonType.vue';

import ContractData from './ContractData.js';
import AlreadyDead from './AlreadyDead.js';

import { SHOP_ADDRESS, SHOP_ABI } from './ShopContract.js';

import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from 'ethers';
let WalletConnectProvider = window.WalletConnectProvider;
import { io } from 'socket.io-client';

//const apiLink = "http://localhost:8082";
const apiLink = "https://already-dead.herokuapp.com";

const socket = io(apiLink);

function preloadImages(array) {
    if (!preloadImages.list) {
        preloadImages.list = [];
    }
    var list = preloadImages.list;
    for (var i = 0; i < array.length; i++) {
        var img = new Image();
        img.onload = function() {
            var index = list.indexOf(this);
            if (index !== -1) {
                // remove image from the array once it's loaded
                // for memory consumption reasons
                list.splice(index, 1);
            }
        }
        list.push(img);
        img.src = array[i];
    }
}

preloadImages(
  [
    "https://cf-ipfs.com/ipfs/QmSBKkhCZUta2mW2p3YhtGyBLZ4Wee4Mo4MC1tGvuWDnCp",
    "https://cf-ipfs.com/ipfs/QmTLSbRWQqxz4xFJxr7RsXwGzw31GWXbRKb3Km6riPFefL"
  ]
);

export default {
  name: 'App',
  components: {
    NavBar,
    WelcomeScreen,
    SelectionMenu,
    BurnPage,
    SoulsPage,
    LeaderboardPage,
    ShopPage,
    DicePage,
    QuestCreator,
    DiscordAuth,
    ButtonType
  },
  data() {
    return {
      music: null,
      entered: false,
      windowHeight: 0,
      windowWidth: 0,
      connected: false,
      correctChain: true,
      selectedAccount: "",
      page: "main",

      //Burning stuff
      burning: false,
      idBurning: 0,
      nextBurn: 0,
      burnsLeft: 0,
      burningWallet: "",
      preburningwallet: "",
      wallet: "",
      pilgrims: [],


      burntSouls: [],
      loadedSouls: false,
      pageNum: 0,
      
      burners: [],
      souls: [],
      total: 0,

      shopItems: [],
      shopOpen: false,
      txStage: "none",

      signDiscord: false,
      discordAuth: "",
    }
  },
  created() {
    let uri = window.location.href.split('#');
    if(uri.length == 2) {
      let vars = uri[1].split('&');
      let getVars = {};
      let tmp = '';
      vars.forEach(function(v) {
        tmp = v.split('=');
        if(tmp.length == 2)
          getVars[tmp[0]] = tmp[1];
      });
      
      if(getVars.access_token) {
        console.log("Access token: " + getVars.access_token)

        this.discordAuth = getVars.access_token;

        socket.on("discordauth", data => {
          document.getElementById('signmessage').innerHTML = data.message;


          setTimeout(() => {
            this.signDiscord = false;
          }, 10000);
      })

        this.signDiscord = true;
        this.connectWallet();
      }

    }
  },
  methods: {
    async authDiscord() {
      let web3 = new Web3(this.provider);
      var rng = Math.floor(Math.random() * 50000000);
      var action = "Link Discord to Wagdie.net";

      console.log(this.selectedAccount);
      
      var hash = "WAGDIE\n" + "Action: " + action + "\nEntropy: " + rng +"\nAuth: " + this.discordAuth;
      var sig = await web3.eth.personal.sign(hash, this.selectedAccount);

      socket.emit('verify discord', rng, action, hash, sig, this.selectedAccount, this.discordAuth);
    },
    async initListener() {
    const provider = new ethers.providers.InfuraProvider("mainnet", "ecb1186e8a214329b7f477254d8de282");
    const contract = new ethers.Contract(SHOP_ADDRESS, SHOP_ABI, provider);
    let t = this;


      contract.on("TokenBought", async (token, supply) => {
        let tokenID = Number(token);
        let amount = Number(supply);
        
        t.shopItems.find(x => Number(x.id) === tokenID).supply = amount;

      });

      contract.on("TokenSupplyChanged", async (token, supply) => {
        let tokenID = Number(token);
        let amount = Number(supply);
        
        t.shopItems.find(x => Number(x.id) === tokenID).supply = amount;

      });


    },
    async buyItem(item) {
      let web3 = new Web3(this.provider);
      let instance = new web3.eth.Contract(SHOP_ABI, SHOP_ADDRESS);

      let t = this;

      this.txStage = 'awaiting';
      
      await instance.methods
      .purchaseItem(item.id)
      .send({
        from: t.selectedAccount,
        value: item.price
      })
      .on("transactionHash", function() {
        t.txStage = 'sent'
      })
      .on("receipt", function() {
        t.txStage = 'complete';
      })
      .on("error", function() {
        t.txStage = 'fail';
      });
    },
    async getEnsForDeaths() {

      this.provider = await detectEthereumProvider();

      let provider = new ethers.providers.Web3Provider(this.provider);


      for(let i = 0; i < this.souls.length; i++) {
        this.getEns(false, provider, i);
      }

    },
    async getEnsForLeaderboard() {

      this.provider = await detectEthereumProvider();

      let provider = new ethers.providers.Web3Provider(this.provider);

      for(let i = 0; i < this.burners.length; i++) {
        this.getEns(true, provider, i);
      }

    },
    async getEns(leaderboard, provider, index) {
      const name = await provider.lookupAddress(leaderboard ? this.burners[index].id : this.souls[index].burner);

      if(name !== null) {

        if(leaderboard)
          this.burners[index].id = name;
          
        if(!leaderboard)
          this.souls[index].burner = name;
      }
      
    },
    changePage(amt) {

      if(amt === 500) { //Hacky code to reset page num after page change
        this.pageNum = 0;
        this.getDeaths();
        this.getBurners();
        return;
      }

      const rem = this.total % 6;
      const max = this.total + rem;
      const maxPage = Math.floor(max / 6) - 1;


      this.souls = [];
      this.burners = [];

      this.pageNum += amt;

      if(this.pageNum > maxPage)
        this.pageNum = maxPage;

      if(this.pageNum < 0)
        this.pageNum = 0;

      this.getDeaths();
      this.getBurners();

    },
    async getShopData() {
      const shop_data = await axios.post(
        `https://api.thegraph.com/subgraphs/name/0x-g/wagdie-items`, {
            query: `
        {
          items(where:{buyable:true}) {
            id
            type
            image
            description
            slot
            buyable
            supply
            price
            name
            description
            buytext
            boughttext
          }
        }
        `
        }
      );

      this.shopItems = [];
      let items = shop_data.data.data.items;

      this.shopItems = items;
      
     

    },
    async getBurners() {
      const skip = (this.pageNum * 6);
      const burners = await axios.post(
        `https://api.thegraph.com/subgraphs/name/0x-g/we-are-all-going-to-die`, {
            query: `
        {
          burners(first:6 skip:${skip} orderBy:amountburnt orderDirection:desc){
            id
            amountburnt
          }
        }
        `

        }
      );


      let data = burners.data.data.burners;
      this.burners = data;

      this.getEnsForLeaderboard();

    },
    async getDeaths() {
      const skip = (this.pageNum * 6);
      const total = await axios.post(
        `https://api.thegraph.com/subgraphs/name/0x-g/we-are-all-going-to-die`, {
            query: `
        {
          totals {
            id
            amountburnt
          }
        }
        `

        }
      );

      const results = await axios.post(
        `https://api.thegraph.com/subgraphs/name/0x-g/we-are-all-going-to-die`, {
            query: `
        {
          deaths(first:6 skip:${skip} orderBy:timestamp orderDirection:desc where:{owner:"0x000000000000000000000000000000000000dead"}) {
            id
            timestamp
            owner
            burner
            hash
          }
        }

        `

        }
      );


      let deaths = results.data.data.deaths;

      this.total = total.data.data.totals[0].amountburnt;

      this.souls = deaths;

      this.getEnsForDeaths();

    },
    async claimSoul(id) {
      let web3 = new Web3(this.provider);
      let instance = new web3.eth.Contract(AlreadyDead.abi, AlreadyDead.address);

      var t = this;

      let sig;

      await axios.post(apiLink + "/sig", {
          token: id,
          w: t.selectedAccount
      }
      
      ).then((response) => {

        sig = response.data.signedMessage;
      });

      instance.methods
      .mint(id, sig)
      .send({
        from: t.selectedAccount,
      })
      .on("transactionHash", function() {

      })
      .on("receipt", function() {
        t.getBurntSouls();
        t.loading = false;
        t.loadingText = "";
      })
      .on("error", function() {
        t.loading = false;
        t.loadingText = "";
      });
    },
    async isClaimable(id) {
      let web3 = new Web3(this.provider);
      let instance = new web3.eth.Contract(AlreadyDead.abi, AlreadyDead.address);
      let claimed = false;

      await instance.methods
        .tokenExists(id)
        .call()
        .then((res) => {
          claimed = res;
        });

        return claimed;
    },
    async getBurntSouls() {
      let w = this.selectedAccount;

      w = String(w).toLowerCase();

      this.loadedSouls = false;

      const results = await axios.post(
            `https://api.thegraph.com/subgraphs/name/0x-g/we-are-all-going-to-die`, {
                query: `
                {
                    deaths(where:{
                      burner:"${w}"
                    }){
                      burner
                      id
                    }
                  }`
    
            }
        );

        let data = results.data.data.deaths;

        let newData = [];

        for(let i = 0; i < data.length; i++) {
          let alreadyClaimed = await this.isClaimable(data[i].id);

          if(!alreadyClaimed)
            newData.push({
              id: data[i].id,
              burner: data[i].burner
            });
        }

        let forAll = await this.isClaimable(6031);


        if(!forAll) {
          newData.push({
              id: 6031,
              burner: this.selectedAccount
            });
        }

        this.burntSouls = newData;

        this.loadedSouls = true;

    },
    decrementBurn() {  
      if(this.burnsLeft === 1)
        this.burnsLeft = 0;

      this.idBurning = 0;
    },
    async burnNFT(id) {
      let web3 = new Web3(this.provider);
      let instance = new web3.eth.Contract(ContractData.abi, ContractData.address);

      var t = this;


      instance.methods
      .safeTransferFrom(this.selectedAccount, "0x000000000000000000000000000000000000dEaD", id)
      .send({
        from: t.selectedAccount,
      })
      .on("transactionHash", function() {

      })
      .on("receipt", function() {
        t.pilgrims = t.pilgrims.filter(x => x.id !== id);
        this.index++;
      })
      .on("error", function() {

      });


    },
    async getOwnedWagdies() {

      let wallet = this.selectedAccount.toLowerCase();

      const aliveResults = await axios.post(
        `https://api.thegraph.com/subgraphs/name/0x-g/we-are-all-going-to-die
        `, {
            query: `
            {
                souls(where:{owner:"${wallet}"}){
                  id
                  owner
                }
            }
              `

        }
    );


    this.pilgrims = aliveResults.data.data.souls;


    },
    switchPage(page) {

      if(this.page === 'burns') {
        socket.emit('leave burn');
      }

      this.page = page;

      if(this.page === 'burns') {
        this.getOwnedWagdies();
        socket.emit('join burn');
      }

      if(this.page === 'souls')
        this.getBurntSouls();

      if(this.page === 'leaderboard') {
        this.getDeaths();
        this.getBurners();
      }

      if(this.page === 'shop') {
        this.switchBackground('shop');
      } else {
        this.switchBackground('main');
      }
      

    },
    async connectWallet() {
      this.provider = await detectEthereumProvider();
      if (this.provider === window.ethereum) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((accounts) => {
            this.selectedAccount = Web3.utils.toChecksumAddress(accounts[0]);
            this.afterConnect();
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.provider = new WalletConnectProvider({
          pollingInterval: "10000",
          infuraId: "398c5bdacacf4d1ea9d073cd2f10ccfc",
        });
        this.provider
          .enable()
          .then(async () => {
            const web3 = new Web3(this.provider);
            const accounts = await web3.eth.getAccounts();
            this.selectedAccount = Web3.utils.toChecksumAddress(accounts[0]);
            this.afterConnect();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    async checkChain() {
      var netId = window.ethereum.chainId

      
      if(netId === '0x1')
        this.correctChain = true;
      else
        this.correctChain = false;

    },
    async afterConnect() {
      this.checkChain();
      this.checkEns();
      this.connected = true;
      this.initListener();
    },
    async init() {
      window.ethereum.on('chainChanged', () => this.checkChain());
      window.ethereum.on('accountsChanged', () => this.connectWallet());
      window.ethereum.on('connected', () => this.connectWallet());

      await this.connectWallet();



    },
    async convertAddressToEns() {
      let address = this.preburningwallet;

      this.burningWallet = this.minifyAddress(this.preburningwallet);

      if(address === "")
        return;

      if(!Web3.utils.isAddress(address))
        return;

      const provider = new ethers.providers.Web3Provider(this.provider);
      let name = await provider.lookupAddress(address);

      if(name !== null && name.length < 20)
        this.burningWallet = name;
      else
        this.burningWallet = this.minifyAddress(address);
    },
    async checkEns() {

      let address = this.selectedAccount;

      if(!Web3.utils.isAddress(address))
        return;

      if(!this.correctChain) {
        this.wallet = this.minifyAddress(address);
        return;
      }

      const provider = new ethers.providers.Web3Provider(this.provider);
      let name = await provider.lookupAddress(address);

      if(name !== null && name.length < 20)
        this.wallet = name;
      else
        this.wallet = this.minifyAddress(address);
    },
    minifyAddress(address) {
        return address.substring(0, 5) + "..." + address.substring(address.length - 4, address.length);
    },
    socketInit() {


      socket.on("current burn", data => {


        if(!data.burning)
          return;
        


        this.idBurning = 0;
        this.nextBurn = data.next;
        this.burnsLeft = data.totalToBurn;
        this.preburningwallet = "";
        
        this.convertAddressToEns();

      })


      socket.on("burn data", data => {
        this.burning = false; //Disable first in case it's already true so the watcher picks it up

        this.burning = true;

        this.$refs.bpage.startBurn();

        this.idBurning = data.burning;
        this.nextBurn = data.next;
        this.burnsLeft = data.totalToBurn;
        this.preburningwallet = data.wallet;

        this.convertAddressToEns();

      })

    },
    enter() {
      this.init();
      this.entered = true;
      this.socketInit();
      this.getShopData();

    },
    switchBackground(type) {
      

    }
  },
}
</script>

<style>

@font-face {
  font-family: "Wagdie_Fraktur";
  src: url('./assets/fonts/Wagdie_Fraktur_36-Regular.ttf');
}

#app {
  font-family: Wagdie_Fraktur;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

body {
  margin: 0px;
  background-image: url("./assets/the_void.jpg");
  background-position-x: center;
  background-position-y: top;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.connection-issue {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  font-size: 30px;
}

::-webkit-scrollbar {
    width: 10px;
    scrollbar-gutter: stable;
}

::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background-color: #cecece9a;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border: 6px solid rgba(0,0,0,0.2);
    border-radius: 3px;
}

.main-discord-sign-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 200px;
  flex-direction: column;
  font-size: 25px;
  text-shadow: 2px 2px black;
}

.sign-message-title {
  width: 70%;
  margin: 10px;
}
</style>
