<template>
    <div class="main-container">
        
        <div class="option-container">
            <MenuButton
                :name="'Burns'"
                :image="'flame.png'"
                @click="$emit('switchTo', 'burns')"
            />

            <MenuButton
                :name="'Souls'"
                :image="'soul.png'"
                @click="$emit('switchTo', 'souls')"
            />

            <MenuButton
                :name="'Leaderboard'"
                :image="'foolscrown.png'"
                @click="$emit('switchTo', 'leaderboard')"
            />

            <MenuButton
                :name="'Item Shop'"
                :image="'quest_potion.png'"
                @click="$emit('switchTo', 'shop')"
            />

            <MenuButton
                :name="'WagD20'"
                :image="'Medieval_Dice_v1.png'"
                @click="$emit('switchTo', 'dice')"
            />

            <MenuButton
                :name="'Quest Creator'"
                :image="'gauntlet.png'"
                @click="$emit('switchTo', 'questcreator')"
            />

            <MenuButton
                :name="'Discord Auth'"
                :image="'discord.png'"
                @click="$emit('switchTo', 'discord')"
            />

            
        </div>


    </div>
</template>

<script>

import MenuButton from './MenuButton.vue';

export default {
  data() {
    return {
      
    };
  },
  components: {
      MenuButton
  },
  methods: {
    
  },
  computed: {
    
  },
};
</script>

<style scoped>

.option-container {
    position: relative;
    display: flex;
    justify-content:space-around;
    align-items: center;
    width: 50%;
    flex-wrap: wrap;
    min-width: 400px;
}

.main-container {
    position: relative;
    display: flex;
    justify-content:center;
    align-items: center;
}

</style>
