<template>
    <div class="queue-container">
        Next
        <div class="queue">

            <img class="back" src="../assets/U_bar_med.png"/>
            <img v-show="nextBurn !== 0" class="wagdie" :src="getImage()"/>

        </div>

    </div>
</template>

<script>

import metadata from '../metadata.js';

export default {
  props: ['nextBurn'],  
  data() {
    return {
      
    };
  },
  methods: {
    getImage() {

        if(!this.nextBurn)
            return "";

        
        let id = this.nextBurn.id; 
        
        if(id === 0)
            return;

        let m = metadata.metadata[id-1].image;
        let imageHash = m.split("/")[2];
        let url = "https://ipfs.io/ipfs/" + imageHash


        return url;
    },
  },
  computed: {
    
  },
};
</script>

<style scoped>

.wagdie {
    width: 80%;
    height: 80%;
    border-radius: 20px;
    user-select: none;
    -webkit-user-drag: none;
}

.back {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
    z-index: -1;
    filter: hue-rotate(150deg);
}

.queue-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    user-select: none;
    font-size: 20px;
    margin-top: 20px;
    user-select: none;
    padding: 230px;
    right: 0px;
    z-index: -2;    
}

.queue {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 125px;
}

</style>
