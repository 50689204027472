<template>
    <div class="main-chat-container">

        <div class="character-img-container">
            <img class="character-img" :src="getCharacter()"/>
        </div>

        <div class="chatbox-container">
            <svg class="corner" viewBox="0 0 105.5 130.5">
                <path class="corner-color" d="M105.5,11.25V122.52S14.98,132.29,4.85,23.68c0-.07,.07-.12,.1-.06,2.73,4.11,49.02,71.52,100.54-12.36Z"></path>
                <path class="corner-border" d="M105.5,122.52s-2.11,.23-5.71,.23c-20.03,0-86.35-7.02-94.93-99.07,0-.05,.03-.09,.06-.09,.02,0,.03,0,.04,.03,1.69,2.55,20.18,29.5,46.28,29.5,15.94,0,34.73-10.05,54.27-41.86V0c-1.02,0-1.9,.65-2.65,1.64-18.43,24.33-33.37,44.06-51.23,44.06-24.45,0-42.16-22.54-45.59-26.77-.74-.91-1.6-1.54-2.61-1.54s-1.91,.53-2.55,1.45c-.65,.92-.95,2.16-.84,3.38,2.05,22.01,7.15,49.31,15.87,64.65,7.32,12.87,19.58,23.17,31.32,30.88,20.49,13.48,41.46,12.73,52.57,12.73,2.99,0,5-.15,5.71-.22v-7.76Z"></path>
            </svg>

            <div class="chatbox">

                <div class="chatbox-title">
                    {{getCharacterName()}}
                </div>

                <div class="divider"></div>

                <div class="chatbox-text">
                    {{dialogText}}
                </div>

            </div>

        </div>

    </div>
</template>

<script>
export default {
  props: ['character', 'text'] ,
  data() {
    return {
      dialogText: "",
      timer: null,
    };
  },
  methods: {
    startShowingText() {
        if(this.timer !== null)
            return;

        this.timer = setInterval(() => {
            if(this.text.length === this.dialogText.length) { 
                clearInterval(this.timer);
                return;
            }

            this.dialogText += this.text[this.dialogText.length];
        }, 60);
    },
    getCharacterName() {
        this.startShowingText();
        if(this.character === 'shopkeeper') {
            return "Ravel The Shopkeep";
        }

        return this.character;
    },
    getCharacter() {
        let char = this.character;

        if(char === 'shopkeeper') {
            return require('../assets/WagdieShopkeeper.png');
        }
    }
  },
  computed: {
    
  },
};
</script>

<style scoped>

.character-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.character-img-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
}

.main-chat-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.divider {
    position: relative;
    display: flex;
    height: 3px;
    width: 80%;
    background-color: black;
    border-radius: 20px;
}

.chatbox-title {
    position: relative;
    display: flex;
    color: black;
    margin-top: 20px;
    font-size: 40px;
}

.chatbox-text {
    position: relative;
    display: flex;
    color: black;
    margin-top: 20px;
    font-size: 30px;
}

.chatbox-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
}

.corner {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 10;
    left: -33px;
    bottom: 15px;
}

.corner-color {
    fill: #e6e2d8;
}

.corner-border {
    fill: black;
}

.chatbox {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 600px;
    height: 200px;
    background-color: #e6e2d8;
    border-radius: 10px;
    border-style: solid;
    border-width: 3px;
    border-color: black;
    box-shadow: 2px 2px black;
    flex-direction: column;
}

</style>
