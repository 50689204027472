<template>
    <div class="pilgrim-container">

        <div class="mid-container">
            

            <PilgrimObject
                v-for="(pilgrim) in pilgrims"
                :key="pilgrim"
                :pilgrim="pilgrim"
                @select="select"
            />

        </div>

    </div>
</template>

<script>

import PilgrimObject from './PilgrimObject.vue';

export default {
  props: ['pilgrims'],
  data() {
    return {
      
    };
  },
  components: {
      PilgrimObject
  },
  methods: {
    select(id) {
        this.$emit('burn', id);
    }
  },
  computed: {
    
  },
};
</script>

<style scoped>

.pilgrim-container {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: fit-content;
    margin-top: 50px;
    min-width: 416px;
    max-width: 1000px;
}

.mid-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
    flex-wrap: wrap;
}

</style>
