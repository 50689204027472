<template>
    <div class="main-dice-container">
        <ButtonType
            :text="'Back'"
            :type="'med'"
            :size="1.5"
            :style="{'margin' : '10px'}"
            @click="$emit('back')"
        />

    <div class="dice-main">
        <img v-if="stage !== 2" class="dice-back" src="../assets/U_bar_med.png"/>

    <div v-if="stage === 0 || stage === 1" class="setup">
        <div class="dice-title">
            <label>wagD20</label>
            <img class="dice-img" src="../assets/Medieval_Dice_v1.png"/>
        </div>

         <ButtonType
            :text="'Translate'"
            :type="'long'"
            :size="1.5"
            :style="{'margin' : '10px'}"
            @click="toggleFont"
        />

        <div id="descriptions" class="direction-container">
            <label class="directions">Once you click start you will be displayed a block number, reveal this block number to everyone involved in the randomness</label>
            <label class="directions">When that block is mined the public seed will be filled with that block numbers hash</label>
            <label class="directions">Enter in a secret code first and don't reveal it until you no longer need the randomness (secret code can be whatever you like)</label>
            <label class="directions">Keep the public seed empty if you want it generated fairly or put in a previously used seed along with its secret code pair to regenerate previous randomness</label>
        </div>

        <div class="info-container">
            <label class="info">{{infoMessage}}</label>
        </div>

        

        <div class="seed-input-container">
            <div class="input-main">
                <label>public seed</label>
                <input id="publicseed" type="text"/>
            </div>

            <div class="input-main">
                <label>secret code</label>
                <input id="privateseed" type="text"/>
            </div>
        </div>

        <div class="generate-instance-container">
            <ButtonType
                v-if="blockNumberToUse === null && stage === 0"
                :text="'Start'"
                :type="'med'"
                :size="1.5"
                :style="{'margin' : '10px'}"
                @click="startRandomness"
            />

            <ButtonType
                v-if="blockNumberToUse === null && stage === 1"
                :text="'Continue'"
                :type="'med'"
                :size="1.5"
                :style="{'margin' : '10px'}"
                @click="setStage(2)"
            />

            
        </div>


    </div>



    <div v-if="stage === 2" class="setup">
        
        <div class="dice-title2">
            <label class="dice-text1">wagD20</label>
            <img class="dice-img" src="../assets/Medieval_Dice_v1.png"/>
        </div>



        <div class="min-max-result">
            <img class="roll-bg" src="../assets/U_bar_med.png"/>
            <div class="min-max-container">
                <div class="input-amounts">
                    <label>Min</label>
                    <input id="min" type="number" value="0"/>
                </div>

                <div class="input-amounts">
                    <label>Max</label>
                    <input id="max" type="number" value="100"/>
                </div>
            </div>

            <div class="rollresult-c">
                <label>Roll: {{resultRoll === null ? 'None' : resultRoll}}</label>
                <label>{{'nonce: ' + nonce}}</label>
            </div>

        </div>

        <div class="button-container">
            <ButtonType
                :text="'Undo'"
                :type="'med'"
                :size="1.2"
                :style="{'margin' : '2px'}"
                @click="undoStep"
            />

            <ButtonType
                :text="'Roll'"
                :type="'med'"
                :size="1.2"
                :style="{'margin' : '2px'}"
                @click="generateRandomHash"
            />

            <ButtonType
                :text="'Roll x1000'"
                :type="'med'"
                :size="1.2"
                :style="{'margin' : '2px'}"
                @click="generateRandomHash1000"
            />

        </div>
        
        <ButtonType
            :text="'Download Results'"
            :type="'long'"
            :size="1.5"
            :style="{'margin' : '10px'}"
            @click="downloadResults"
            />

    </div>


</div>



    </div>
</template>

<script>

import ButtonType from './ButtonType.vue';

import { ethers, BigNumber } from 'ethers';
import Web3 from 'web3';

const provider = new ethers.providers.InfuraProvider("homestead")

export default {
  components: {
      ButtonType
  },
  data() {
    return {
      blockNumberToUse: null,
      publicSeed: null,
      privateSeed: null,
      infoMessage: "",
      stage: 0,
      nonce: 0,
      resultHash: "",
      resultRoll: null,
      storedResults: [],
      fontTranslated: false,
    };
  },
  mounted() {
      this.startBlockListener();
  },
  methods: {
    toggleFont() { 
        let element = document.getElementById('descriptions');
        let f = this.fontTranslated ? 'Wagdie_Fraktur' : 'Arial';

        element.style.fontFamily = f;

        this.fontTranslated = !this.fontTranslated;
    },
    setStage(stage) {
        if(stage === 2) {
            let publicE = document.getElementById('publicseed');
            let privateE = document.getElementById('privateseed');

            publicE.value = "";
            privateE.value = "";

            publicE.disabled = false;
            privateE.disabled = false;
        }

        this.stage = stage;
    },
    undoStep() {
        this.storedResults = this.storedResults.filter(x => x.nonce !== this.nonce && x.nonce !== (this.nonce - 1));

        this.nonce -= 2;

        if(this.nonce < 0)
            this.nonce = 0;

        this.generateRandomHash();
    },
    generateRandomHash1000() {
        for(let i = 0; i < 1000; i++) {
            this.generateRandomHash();
        }
    },
    generateRandomHash() {
        let min = document.getElementById('min').value;
        let max = document.getElementById('max').value;

        if(Number(min) < 0 || Number(max) < 0) {
            alert(`Number can't be blow zero..`);
            return;
        }

        this.nonce++;

        let rngHash = ethers.utils.solidityKeccak256(["string", "string", "uint256", "uint256", "uint256"], [this.publicSeed, this.privateSeed, min, max, this.nonce]);
        
        let int = Web3.utils.hexToNumberString(rngHash);

        int = BigNumber.from(int).mod(Number(max));

        int = int.toNumber();

        int += Number(min);
            
        this.resultHash = rngHash;

        this.resultRoll = int;

        this.storedResults.push({
            min: Number(min),
            max: Number(max),
            pubKey: this.publicSeed,
            secretCode: this.privateSeed,
            roll: int,
            nonce: this.nonce
        });


    },
    ConvertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = 'min,max,public,secret,roll,nonce\r\n';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    },
    downloadResults() {
        var textDoc = document.createElement('a');

        //textDoc.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(JSON.stringify(this.storedResults));
        textDoc.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(this.ConvertToCSV(JSON.stringify(this.storedResults)));
        textDoc.target = '_blank';
        textDoc.download = 'RandomResults.txt';
        textDoc.click();

    },
    async startBlockListener() {
        provider.on("block", async (blockNum) => {

            if(this.blockNumberToUse !== null) {
                this.infoMessage = "Waiting for block " + this.blockNumberToUse + " to be mined.. current: " + blockNum;
            }

            if(blockNum === this.blockNumberToUse) {
                let block = await provider.getBlock(blockNum);

                this.publicSeed = block.hash;
                let publicE = document.getElementById('publicseed');
                publicE.value = this.publicSeed;
                this.blockNumberToUse = null;
                this.infoMessage = "Click continue when ready!"
                this.stage = 1;
            }
        })
    },
    async startRandomness() {
        let publicE = document.getElementById('publicseed');
        let privateE = document.getElementById('privateseed');

        let getUnknownRandomness = publicE.value.length === 0;

        if(privateE.value.length === 0) {
            alert('You must enter a secret code first.');
            return;
        }

        if(getUnknownRandomness) {
            let currentBlockNumber = await provider.getBlockNumber();
            let getSeedFrom = currentBlockNumber + 5;

            this.blockNumberToUse = getSeedFrom;
            this.privateSeed = privateE.value;

            publicE.disabled = true;
            privateE.disabled = true;
            this.infoMessage = "Waiting for block " + this.blockNumberToUse + " to be mined.. current: " + currentBlockNumber;

            return;
        }

        this.publicSeed = publicE.value;
        this.privateSeed = privateE.value;

        publicE.disabled = true;
        privateE.disabled = true;

        this.infoMessage = "Click continue when ready! Take note of your public and secret code!"
        this.stage = 1;        
    },
  },
  computed: {
    
  },
};
</script>

<style scoped>

.dice-text1 {
    position: absolute;
    top: 30px;
}

.roll-bg {
    position: absolute;
    width: 256px;
    height: 256px;
    z-index: -2;
    left: 225px;
    top: 0px;
}

.button-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.nonce-c {
    display: flex;
    flex-direction: column;
}

.rollresult-c {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 150px;
}


.min-max-result {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 30px;
}


.input-amounts {
    width: 75px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

input {
    text-align: center;
}

.min-max-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    margin: 10px;
}

.s-c {
    display: flex;
    flex-direction: column;
}

.seeds-container {
    margin: 20px;
    display: flex;
    flex-direction: column;
}

.seedtext {
    font-size: 25px;
}

.seedcode {
    font-size: 15px;
}

.main-dice-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-shadow: 2px 2px black;    
}

.dice-main {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 700px;
    height: 700px;
}

.dice-back {
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
    position: absolute;
    top: 0px;
    z-index: -1;
}

.dice-title2 {
    position: absolute;
    top: -60px;
    left: 290px;
    font-size: 40px;
    display: flex;
    flex-direction: column;
}

.dice-title {
    margin-top: 30px;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
}

.seed-input-container {
    display: flex;
    flex-direction: row;
}

.input-main {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.generate-instance-container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.directions {
    margin: 10px;
    width: 80%;
}

.direction-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.setup {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info-container {
    margin: 10px;
    color: rgb(165, 0, 0);
}

.dice-img {
    position: absolute;
    margin-left: 20px;
    top: 5px;
    width: 100px;
    height: 100px;
    z-index: -1;
}

</style>
