<template>
    <div class="main-container">

        <div class="img-container">
            <img class="img-bg" src="../assets/U_bar_med.png"/>
            <img class="img" src="../assets/mainimage.png"/>
        </div>

        <ButtonType
        class="enter-button"
        :text="'Enter'"
        :type="'long'"
        :size="2.5"
        :style="{'margin' : '10px'}"
        @click="$emit('enter')"
        />
    </div>

</template>

<script>

import ButtonType from './ButtonType.vue';

export default {
  data() {
    return {
      
    };
  },
  components: {
      ButtonType
  },
  methods: {
    
  },
  computed: {
    
  },
};
</script>

<style scoped>

.img-container {
    position: relative;
    display: flex;
    width: 486px;
    height: 342px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.img {
    position: absolute;
    display: flex;
    width: 92%;
    height: 92%;
    border-radius: 20px;
    -webkit-user-drag: none;
}

.img-bg {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    -webkit-user-drag: none;
}

.main-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    flex-direction: column;
    user-select: none;
}

</style>
