<template>
    
    <div class="main-top">
        <label class="title-bar">wagdie</label>

        <Wallet
            class="wallet"
            :wallet="wallet"
        />

    </div>

</template>

<script>

import Wallet from './Wallet.vue';

export default {
  props: ['wallet'],  
  data() {
    return {
      
    };
  },
  components: {
      Wallet
  },
  methods: {
    
  },
  computed: {
    
  },
};
</script>

<style scoped>

.title-bar {
    position: absolute;
    display: flex;
    top: 10px;
    left: 10px;
    font-size: 35px;
    text-shadow: 2px 2px black;
    user-select: none;
}


.main-top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 70px;
}

.wallet {
    position: absolute;
    top: 15px;
    right: 10px;
}

</style>
