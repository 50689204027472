<template>
    <div class="wallet-main">
        <img class="wallet-img" src="../assets/title_bar_1.png"/>
        <label class="wallet">{{wallet}}</label>
    </div>
</template>

<script>
export default {
  props: ['wallet'],
  data() {
    return {
      
    };
  },
  methods: {

  },
  computed: {
    
  },
};
</script>

<style scoped>

.wallet {
    position: relative;
    display: flex;
    padding-top: 5px;
    cursor: pointer;
}

.wallet-img {
    position: absolute;
    display: flex;
    width: 150px;
    height: 60px;
    z-index: -1;
    -webkit-user-drag: none;
    cursor: pointer;
}

.wallet-main {
    position: relative;
    display: flex;
    width: 110px;
    height: 27px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    user-select: none;
}



</style>
