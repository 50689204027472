<template>
    <div @mouseenter="enter" @mouseleave="leave" v-if="image !== ''" :style="getSize()" class="button-container">
        <img class="img" :src="getType()" />
        <label :style="{'font-size' : fontsize ? (fontsize + 'px') : (size * 10) + 'px'}" class="text">{{text}}</label>
    </div>
</template>

<script>
export default {
  props: ['type', 'size', 'text', 'fontsize'],  
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    enter() {
        this.hover = true;
    },
    leave() {
        this.hover = false;
    },
    getSize() {
        let type = this.type;
        let size = Number(this.size);

        let data = this.getBase(type, size);

        return {
            "width" : data.x + "px",
            "height" : data.y + "px",
            "min-width" : data.x + "px",
            "min-height" : data.y + "px"
        }
    },
    getBase(type, size) {
        if(type === 'long') {
            return {
                x: Math.floor(102.4 * size),
                y: Math.floor(25.6 * size)
            }
        }

        if(type === 'med') {
            return {
                x: Math.floor(51.2 * size),
                y: Math.floor(25.6 * size)
            }
        }
    },
    getType() {
        let type = this.type;
        
        if(type === 'long') {
            if(this.hover)
                return require('../assets/LongButtonOn.png');
            else
                return require('../assets/LongButton.png');
        }

        if(type === 'med') {
            if(this.hover)
                return require('../assets/CleanMedButtonOn.png');
            else
                return require('../assets/CleanMedButtonOff.png');
        }
    },
  },
  computed: {
    
  },
};
</script>

<style scoped>

.button-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.text {
    position: relative;
    display: flex;
    user-select: none;
    cursor: pointer;
}

.img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: hue-rotate(150deg);
}

</style>
