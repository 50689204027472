<template>
    <div id="capture" class="main-container">

        <div class="option-container">

            <div @click="leftHidden = !leftHidden" class="hide-option">
                {{leftHidden ? 'show' : 'hide'}} left
            </div>


            <div @click="rightHidden = !rightHidden" class="hide-option">
                {{rightHidden ? 'show' : 'hide'}} right
            </div>

            

        </div>

        <div class="game-container">
            <img class="back-img" src="@/assets/background.png"/>

            <div class="top-section">
                
                <div contenteditable="true" spellcheck="false" class="title-container">
                    wagdie quest
                </div>

                <div contenteditable="true" spellcheck="false" class="quest-info-container">
                    quest information
                </div>

            </div>


            <div id="capture2" class="middle-section">

                <div v-if="leftHidden" class="left-attackers"/>
                <div v-if="!leftHidden" class="left-attackers">
                    
                    <Character
                        v-for="(leftSide) in leftSideData"
                        :key="leftSide.id"
                        :id="leftSide.id"
                        :tokenid="leftSide.tokenId"
                        :active="isIdActive(leftSide.id)"
                        :hp="leftSide.hp"
                        :damage="leftSide.damage"
                        :maxhp="leftSide.maxHp"
                        :name="leftSide.name"
                        :side="'left'"
                        :overrideImage="leftSide.overrideImage"
                        @click="selectChar(leftSide.id)"
                    />


                </div>




                <div v-if="rightHidden" class="right-attackers"/>
                <div v-if="!rightHidden" class="right-attackers">

                    <Character
                        v-for="(rightSide) in rightSideData"
                        :key="rightSide.id"
                        :id="rightSide.id"
                        :tokenid="rightSide.tokenId"
                        :active="isIdActive(rightSide.id)"
                        :hp="rightSide.hp"
                        :damage="rightSide.damage"
                        :maxhp="rightSide.maxHp"
                        :name="rightSide.name"
                        :side="'right'"
                        :overrideImage="rightSide.overrideImage"
                        @click="selectChar(rightSide.id)"
                    />

                </div>
            </div>

        </div>


    </div>

    <div class="quest-control-c">
        <QuestController
            ref="questcontroller"
            @createCharLeft="createCharLeft"
            @createCharRight="createCharRight"
            @removeChar="removeChar"
            @makeActive="makeActive"
            @uploadrng="uploadRng"
            @steprng="stepRng"
            @screenshot="screenshot"
            @download="downloadQuest"
            @uploadquest="uploadQuest"
        />
    </div>
    
</template>

<script>

import Character from './Character.vue';
import QuestController from './QuestController.vue';

export default {
  components: {
      Character,
      QuestController
  },  
  data() {
    return {
      leftHidden: false,
      midHidden: false,
      rightHidden: false,
      active: [],
      selected: null,
      leftSideData: [],
      rightSideData: [],
      rngData: [],
      rngStep: 0,
      characterStep: 0,

      characterLeft: null,
      characterRight: null,
      characterLeftStep: 0,
      characterRightStep: 0,
    };
  },
  mounted() {
      this.init();
  },
  methods: {

    stepRng() {

        if(this.rngData.length === 0) {
            alert("Please upload rng first.");
            return;
        }

        if(this.checkIfWinner()) {
            alert("One side already won");
            this.resetDamageDoneForAll();
            this.active = [];
            return;
        }

        if(this.characterLeft === null || this.characterRight === null) {
            this.resetDamageDoneForAll();

            let maxLeft = this.leftSideData.length;
            let maxRight = this.rightSideData.length;

            if(this.characterLeftStep >= maxLeft)
                this.characterLeftStep = 0;

            if(this.characterRightStep >= maxRight)
                this.characterRightStep = 0;


            this.characterLeft = this.leftSideData[this.characterLeftStep];
            this.characterRight = this.rightSideData[this.characterRightStep];

            this.characterLeftStep++;
            this.characterRightStep++;

            if(this.characterLeft.hp <= 0) {
                this.characterLeft = null;
                this.characterRightStep--;
                this.stepRng();
                return;
            }

            if(this.characterRight.hp <= 0) {
                this.characterRight = null;
                this.characterLeftStep--;
                this.stepRng();
                return;
            }

            

            this.makeActive(this.characterLeft, false);
            this.makeActive(this.characterRight, false);

            console.log("Active: ", this.active);

            return;
        }

        if(this.rngStep >= this.rngData.length) {
            alert("Ran out of rng rolls, restarting rng from the start again.");
            this.rngStep = 0;
        }


        let roll = this.rngData[this.rngStep++];

        console.log("Rng: " + roll);

        let leftAttack = (roll % 2) === 0;

        console.log("left attack: " + leftAttack)

        console.log("Roll: " + ((roll + 1234) % 2));

        //let attacker = leftAttack ? this.characterLeft : this.characterRight;

        let defender = leftAttack ? this.characterRight : this.characterLeft;

        defender.hp -= roll;
        defender.damage = Number(roll);

        if(defender.hp < 0)
            defender.hp = 0;

        this.characterLeft = null;
        this.characterRight = null;


        

    },
    ConvertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    },
    downloadQuest() {
        var textDoc = document.createElement('a');

        const bothArrays = this.leftSideData.concat(this.rightSideData);

        //textDoc.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(JSON.stringify(this.storedResults));
        textDoc.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(this.ConvertToCSV(JSON.stringify(bothArrays)));
        textDoc.target = '_blank';
        textDoc.download = 'QuestData.txt';
        textDoc.click();

        textDoc.remove();

    },
    checkIfWinner() {
        let leftAlive = false;
        let rightAlive = false;

        for(let i = 0; i < this.leftSideData.length; i++) {
            if(this.leftSideData[i].hp > 0)
                leftAlive = true;
        }

        for(let i = 0; i < this.rightSideData.length; i++) {
            if(this.rightSideData[i].hp > 0)
                rightAlive = true;
        }

        return leftAlive !== rightAlive;
    },
    isIdActive(id) {
        for(let i = 0; i < this.active.length; i++) {
            if(parseInt(this.active[i]) === parseInt(id))
                return true;
        }

        return false;
    },
    makeActive(char, toggle) {

        let id = parseInt(char.id);

        if(this.active.length === 0) {
            this.active.length = 2;
            this.active[0] = -1;
            this.active[1] = -1;
        }

        if(char.side === 'left') {
            if(this.active[0] === id && toggle) {
                this.active[0] = -1;
                return;
            }

            this.active[0] = id;
        } else {
            if(this.active[1] === id && toggle) {
                this.active[1] = -1;
                return;
            }

            this.active[1] = id;
        }

    },
    resetDamageDoneForAll() {
        for(let i = 0; i < this.leftSideData.length; i++) {
            this.leftSideData[i].damage = 0;
        }

        for(let i = 0; i < this.rightSideData.length; i++) {
            this.rightSideData[i].damage = 0;
        }
    },
    uploadQuest(splitFiles) {
        //Get rid of top and bottom part.
        splitFiles.pop();

        this.rightSideData = [];
        this.leftSideData = [];

        for(let i = 0; i < splitFiles.length; i++) {
            let splitLine = splitFiles[i].split(',');

            let _id = parseInt(splitLine[0]);
            let _hp = parseInt(splitLine[1]);
            let _maxHp = parseInt(splitLine[2]);
            let _name = String(splitLine[3]);
            let _damage = parseInt(splitLine[4]);
            let _token = parseInt(splitLine[5]);
            let _overrideImage = String(splitLine[6]);
            let _side = String(splitLine[7]);


            if(_side === 'left\r') {

                this.leftSideData.push({
                    id: _id,
                    hp: _hp,
                    maxHp: _maxHp,
                    name: _name,
                    damage: _damage,
                    overrideImage: _overrideImage,
                    side: _side,
                    tokenId: _token
                });


            }

            if(_side === 'right\r') {

                this.rightSideData.push({
                    id: _id,
                    hp: _hp,
                    maxHp: _maxHp,
                    name: _name,
                    damage: _damage,
                    overrideImage: _overrideImage,
                    side: _side,
                    tokenId: _token
                });

                
            }
        }
    },
    uploadRng(splitFiles) {

        //Get rid of top and bottom part.
        splitFiles.shift();
        splitFiles.pop();

        for(let i = 0; i < splitFiles.length; i++) {
            let splitLine = splitFiles[i].split(',');
            this.rngData.push(splitLine[4]);
        }


    },
    removeChar(id) {
        this.leftSideData = this.leftSideData.filter(x => x.id !== id);
        this.rightSideData = this.rightSideData.filter(x => x.id !== id);
    },
    selectChar(id) {
        let char = this.leftSideData.find( x => x.id === id);

        if(!char) {
            char = this.rightSideData.find( x => x.id === id);
        }

        this.$refs.questcontroller.editChar(char);

    },
    createCharLeft(char) {
        if(this.leftSideData.length === 16) {
            alert("Max amount of 16 per side");
            return;
        }

        let id = this.leftSideData.length + this.rightSideData.length;

        char.id = id;

        this.leftSideData.push(char);

    },
    createCharRight(char) {
        if(this.rightSideData.length === 16) {
            alert("Max amount of 16 per side");
            return;
        }

        let id = this.leftSideData.length + this.rightSideData.length;

        char.id = id;

        this.rightSideData.push(char);

    },
    init() {
        
        this.leftSideData.push({
            id: 0,
            hp: 1000,
            maxHp: 1000,
            name: "test 1",
            damage: 0,
            tokenId: 10,
            overrideImage: "",
            side: 'left'
        });

        this.leftSideData.push({
            id: 1,
            hp: 1000,
            maxHp: 1000,
            name: "test 2",
            damage: 0,
            tokenId: 11,
            overrideImage: "",
            side: 'left'
        });

        this.rightSideData.push({
            id: 2,
            hp: 1000,
            maxHp: 1000,
            name: "test 3",
            damage: 0,
            tokenId: 12,
            overrideImage: "",
            side: 'right'
        });

        this.rightSideData.push({
            id: 3,
            hp: 1000,
            maxHp: 1000,
            name: "test 4",
            damage: 0,
            tokenId: 14,
            overrideImage: "",
            side: 'right'
        });

    }
  },
  computed: {
    
  },
};
</script>

<style scoped>

.quest-control-c {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hide-option {
    width: 100%;
    font-size: 30px;
    user-select: none;
    cursor: pointer;
}

.option-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.right-attackers {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
}

.left-attackers {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
}

.middle {
    position: relative;
    background-color: rgba(0, 0, 0, 0.144);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30%;
    background-color: rgba(145, 255, 142, 0.144);
}

.back-img {
    -webkit-user-drag: none;
    user-select: none;
    position: absolute;
    height: 700px;
    width: 1100px;
    z-index: -1;
}

.main-container {
    position: relative;
    display: flex;
    justify-content: center;
    height: 800px;
    width: 100%;
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
}

.game-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    height: 700px;
    width: 1100px;
    margin-top: 50px;
    flex-direction: column;
}

.middle-section {
    position: relative;
    display: flex;
    justify-content: center;
    height: 700px;
    width: 1100px;

}

.top-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    font-size: 25px;
    width: 100%;
}

.title-container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.quest-info-container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}



</style>
