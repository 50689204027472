<template>
    <div :style="{'opacity' : hp > 0 ? '1' : '0.65'}" class="char-container">

        <img class="char-bg" src="../assets/U_bar_med.png" />

        <div v-if="active" class="active-container">
            <label class="active-text">Active</label>
        </div>
        
        <label v-if="!active" class="char-name">{{name}}</label>

        <img :style="{'transform' : getDir()}" class="char-img" :src="getImage()"/>

        <label class="char-dmg" >{{damage > 0 ? ('-' + damage) : ''}}</label>


        <div class="main-hp-container">
            <div class="empty-hp-container"/>

            <div :style="{'clip' : getClip()}" class="hp-container">


            </div>

            <label class="hp-count">{{`${hp}/${maxhp}`}}</label>

        </div>
        

    </div>
</template>

<script>

import metadata from '../metadata.js';


export default {
  props: ['id', 'active', 'hp', 'maxhp', 'name', 'overrideImage', 'tokenid', 'damage', 'side'],  
  data() {
    return {
      
    };
  },
  methods: {
    getDir() {
        if(this.side === 'left')
            return "";

        return "scaleX(-1)"
    },
    getClip() {
        const max = this.maxhp;
        const hp = this.hp;

        const percent = hp / max;

        const x = (100 * percent);

        return `rect(0px, ${x}px, 180px, 0px)`
    },
    getImage() {

        let id = this.tokenid; 

        if(this.overrideImage !== "")
            return this.overrideImage;
        
        if(id === 0)
            return;


        let m = metadata.metadata[id-1].image;
        let imageHash = m.split("/")[2];
        let url = "https://ipfs.io/ipfs/" + imageHash


        return url;
    },
  },
  computed: {
    
  },
};
</script>

<style scoped>

.char-dmg {
    position: absolute;
    display: flex;
    bottom: -27px;
    color: red;
    font-size: 22px;
}

.char-bg {
    position: absolute;
    width: 100%;
    height: 100%;
}

.char-container {
    position: relative;
    display: flex;
    justify-content: center;
    height: 115px;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.342);
    box-shadow: 2px 2px rgba(0, 0, 0, 0.486);
    margin: 10px;
    margin-top: 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
}

.char-img {
    height: 75px;
    width: 75px;
    position: absolute;
    top: 15px;
    border-radius: 3px;
}

.char-name {
    position: absolute;
    top: -18px;
    width: 200px;
    color: black;
}

.empty-hp-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15px;
    background-color: rgb(82, 82, 82);
    border-radius: 5px;
    box-shadow: 1px 1px rgba(0, 0, 0, 0.356);
}

.hp-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15px;
    background-color: red;
    border-radius: 5px;
    box-shadow: 1px 1px rgba(0, 0, 0, 0.356);
}

.main-hp-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15px;
    bottom: 0px;
}

.hp-count {
    z-index: 10;
    font-size: 13px;
    
}

.active-container {
    position: absolute;
    display: flex;
    top: -22px;
    width: 100%;
    height: 20px;
    background: green;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px rgba(0, 0, 0, 0.616);
}

.active-text {
    position: absolute;
    top: 3px;
}
</style>
