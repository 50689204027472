<template>
    <div class="pilgrim">
        <img class="pilgrim-img" :src="getImage(pilgrim.id)"/>
        <label class="pilgrim-text">#{{pilgrim.id}}</label>

        <ButtonType
            :text="'Burn'"
            :type="'med'"
            :size="1.6"
            :style="{'margin' : '10px'}"
            @click="$emit('select', pilgrim.id)"
        />

    </div>
</template>

<script>

import metadata from '../metadata.js';
import ButtonType from './ButtonType.vue';

export default {
  props: ['pilgrim'],  
  data() {
    return {
      
    };
  },
  components: {
      ButtonType
  },
  methods: {
    getImage(id) {
        
        if(id === 0) {
            this.show = false;
            return;
        }


        let m = metadata.metadata[id-1].image;
        let imageHash = m.split("/")[2];
        let url = "https://ipfs.io/ipfs/" + imageHash


        return url;
    },
  },
  computed: {
    
  },
};
</script>

<style scoped>

.pilgrim-text {
    position: absolute;
    color: white;
    text-shadow: 1px 1px black;
}

.pilgrim-img {
    position: relative;
    display: flex;
    width: 125px;
    height: 125px;
    border-radius: 10px;
    -webkit-user-drag: none;
}

.pilgrim {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 200px;
    width: 150px;
    margin: 10px;
    flex-direction: column;
    user-select: none;
}

</style>
