<template>
    <div class="main-shop-container">

      <ButtonType
            v-if="shopOpen && txStage === 'none'"
            :text="'Back'"
            :type="'med'"
            :size="1.5"
            :style="{'margin' : '10px'}"
            @click="goBack"
        />

        <ButtonType
            v-if="shopOpen && txStage !== 'none'"
            :text="'Back'"
            :type="'med'"
            :size="1.5"
            :style="{'margin' : '10px'}"
            @click="$emit('resetStage')"
        />

        <ChatBox
          v-if="!shopOpen"
          :character="'shopkeeper'"
          :text="'Hello pilgrim, welcome to my shop. Feel free to browse my items to help on your adventures'"
        />

        <ChatBox
          v-if="txStage === 'awaiting' && lastClicked !== null"
          :character="'shopkeeper'"
          :text="lastClicked.buytext"
        />

        <ChatBox
          v-if="txStage === 'sent' && lastClicked !== null"
          :character="'shopkeeper'"
          :text="'Alright, let me go grab it.. one moment.'"
        />

        <ChatBox
          v-if="txStage === 'complete' && lastClicked !== null"
          :character="'shopkeeper'"
          :text="lastClicked.boughttext"
        />

        <ChatBox
          v-if="txStage === 'fail' && lastClicked !== null"
          :character="'shopkeeper'"
          :text="`Sorry, I'm afraid I can't make this transaction. Maybe try again.`"
        />

        <ButtonType
            v-if="shopOpen && (txStage === 'fail' || txStage === 'complete')"
            :text="'Finish'"
            :type="'med'"
            :size="1.5"
            :style="{'margin' : '10px'}"
            @click="$emit('resetStage')"
        />

        <ButtonType
            v-if="!shopOpen"
            :text="'Enter'"
            :type="'med'"
            :size="1.5"
            :style="{'margin' : '10px'}"
            @click="shopOpen = true"
        />

        <div v-if="shopOpen && txStage === 'none'" class="shop-container">

          <div class="fund-info">
            <div class="fund-info-container">
              <img class="background-funds" src="../assets/U_skill_bar.png"/>

              <label class="title-fund">Funds use</label>
              <label>50% to fund prizes</label>
              <label>30% back to conclave</label>
              <label>10% to quest host</label>
              <label>10% to development</label>
            </div>
          </div>
          
          

          <div class="items-container">

            <div v-for="shop_item in shopItems" :key="shop_item" class="shop-item">

              <div :style="getTypeColor(shop_item)" class="item-type-container">
                <label>{{shop_item.type}}</label>
              </div>

              <img class="background-item" src="../assets/U_bar_med.png"/>
              
              <div class="item">
                <img class="item-back" src="../assets/mini_button_fr.png"/>
                <img class="item-img" :src="shop_item.image"/>

                <h4 :style="getSupplyColor(shop_item)" class="item-supply">{{shop_item.supply > 0 ? shop_item.supply + ' left' : 'sold out'}}</h4>
              </div>

              <div class="info-container">
                <h2 class="item-name">{{shop_item.name}}</h2>

                <h3 class="item-desc">{{shop_item.description}}</h3>

                <ButtonType
                  v-if="shop_item.supply > 0"
                  :text="`Buy for ${getPrice(shop_item.price)} eth`"
                  :type="'long'"
                  :size="1.8"
                  :style="{'z-index' : 2}"
                  @click="selectItem(shop_item)"
                  class="buy-button"
                />
                
                
              </div>

            </div>

          </div>


        </div>

    </div>
</template>

<script>

import ButtonType from './ButtonType.vue';
import Web3 from 'web3';
import ChatBox from './ChatBox.vue';

export default {
  props: ['shopItems', 'txStage'],
  components: {
    ButtonType,
    ChatBox
  },
  data() {
    return {
      shopOpen: false,
      lastClicked: null,
    };
  },
  methods: {
    selectItem(shopItem) {
      this.lastClicked = shopItem;

      this.$emit('buy', shopItem)
    },
    getTypeColor(shopItem) {

      if(shopItem.type === "Artifact") {
        return {
            "background-color" : "hsla(227, 69%, 52%, 1)",
            "box-shadow" : "rgb(39, 69, 175)",
            "border-color" : "rgb(39, 69, 175)"
        }
      }

      if(shopItem.type === "Consumable") {
        return {
            "background-color" : "hsla(38, 100%, 45%, 1)",
            "box-shadow" : "rgba(138, 87, 0, 1)",
            "border-color" : "rgba(138, 87, 0, 1)"
        }
      }

      
    },
    getSupplyColor(shopItem) {
      if(shopItem.supply <= 0)
        return {'color' : 'hsla(360, 69%, 52%, 1)'};

      return {'color' : 'white'}
    },
    goBack() {
      this.shopOpen = false;
      this.$emit('back');
    },
    getPrice(wei) {
      return Web3.utils.fromWei(wei, 'ether');
    }
  },
  computed: {
    
  },
};
</script>

<style scoped>

.title-fund {
  font-size: 25px;
  margin-bottom: 5px;
}

.background-funds {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.fund-info {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fund-info-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 120px;
}

.item-type-container {
  position: absolute;
  display: flex;
  width: 100px;
  height: 25px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  top: -15px;
}

.background-item {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.buy-button {
  position: absolute;
  bottom: 20px;
  right: 80px;
}

.item-supply {
  position: absolute;
  bottom: -40px;
}

.info-container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item-name {
  margin: 20px;
  text-shadow: 2px 2px black;
}

.item-price {
  font-family: sans-serif;
}

.item-desc {
  margin: 20px;
  text-shadow: 2px 2px black;
}

.item-back {
  position: absolute;
  height: 100%;
  width: 100%;
}

.item-img {
  height: 90%;
  width: 90%;
  border-radius: 20px;
}

.item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  flex-direction: column;  
  margin-top: 20px;
}

.shop-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 350px;
  min-width: 350px;
  border-radius: 20px;
  margin-top: 30px;
  margin: 30px;
  flex-direction: column;
}

.items-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}


.shop-container {
  margin-top: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.main-shop-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

</style>
