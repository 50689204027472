<template>
    <div class="main-control-container">

        <div v-if="state === 'main'" class="main-top-c">

        <ButtonType
            :text="'Add character left'"
            :type="'med'"
            :fontsize="17"
            :size="3"
            :style="{'margin' : '10px'}"
            @click="state = 'left-char'"
        />

        <ButtonType
            :text="'Add character right'"
            :type="'med'"
            :fontsize="17"
            :size="3"
            :style="{'margin' : '10px'}"
            @click="state = 'right-char'"
        />

        <ButtonType
            :text="'Step rng'"
            :type="'med'"
            :fontsize="17"
            :size="3"
            :style="{'margin' : '10px'}"
            @click="$emit('steprng')"
        />

        <ButtonType
            :text="'Download Quest'"
            :type="'med'"
            :fontsize="17"
            :size="3"
            :style="{'margin' : '10px'}"
            @click="$emit('download')"
        />

        <ButtonType
            :text="'Upload Rng'"
            :type="'med'"
            :fontsize="17"
            :size="3"
            :style="{'margin' : '10px'}"
            @click="state = 'uploadrng'"
        />

        <ButtonType
            :text="'Upload quest'"
            :type="'med'"
            :fontsize="17"
            :size="3"
            :style="{'margin' : '10px'}"
            @click="state = 'uploadquest'"
        />

        </div>

        <div v-show="state === 'uploadrng'" class="file-upload-c">

            <ButtonType
                :text="'Back'"
                :type="'med'"
                :fontsize="20"
                :size="1.5"
                :style="{'margin' : '10px'}"
                @click="back"
            />


            <label>Upload randomresults from wagd20</label>
            <input type="file" name="inputfile"
            id="inputfile">
        </div>

        <div v-show="state === 'uploadquest'" class="file-upload-c">

            <ButtonType
                :text="'Back'"
                :type="'med'"
                :fontsize="20"
                :size="1.5"
                :style="{'margin' : '10px'}"
                @click="back"
            />


            <label>Upload quest layout</label>
            <input type="file" name="inputfile"
            id="inputfile2">
        </div>

        <div v-show="state === 'left-char' || state === 'right-char' || state === 'editchar'" class="main-c">

        <ButtonType
            :text="'Back'"
            :type="'med'"
            :fontsize="20"
            :size="1.5"
            :style="{'margin' : '10px'}"
            @click="back"
        />
        
        

        <div class="token-creator">
            <input id="tokenid" placeholder="tokenid" />
            <input id="hp" placeholder="hp" />
            <input id="maxhp" placeholder="max hp" />
            <input id="name" placeholder="name" />
            <input id="override" placeholder="override image link" />
            <input id="damage" placeholder="damage taken" />

            <div class="button-container">
               <ButtonType
                    :text="'Submit'"
                    :type="'med'"
                    :fontsize="15"
                    :size="1.5"
                    :style="{'margin' : '10px'}"
                    @click="addChar"
                /> 

                <ButtonType
                    v-if="edit"
                    :text="'Remove'"
                    :type="'med'"
                    :fontsize="15"
                    :size="1.5"
                    :style="{'margin' : '10px'}"
                    @click="removeChar"
                /> 

                <ButtonType
                    v-if="edit"
                    :text="'Active'"
                    :type="'med'"
                    :fontsize="15"
                    :size="1.5"
                    :style="{'margin' : '10px'}"
                    @click="makeActive"
                /> 

                

            </div>

            
            
        </div>

        
        </div>

        

    </div>
</template>

<script>

import ButtonType from './ButtonType.vue';

export default {
  data() {
    return {
      state: "main",
      edit: false,
      editingChar: null,
      rngExists: false,
    };
  },
  components: {
      ButtonType
  },
  mounted() {
      this.init();
  },
  methods: {
    init() {
        let t = this;

        document.getElementById('inputfile')
            .addEventListener('change', function() {

            if(document.getElementById('inputfile').value === null)
                return;

              
            var fr=new FileReader();

            fr.onload=function(){
                let res = fr.result;

                let split = res.split("\n");

                t.rngExists = true;

                t.state = 'main';

                t.$emit('uploadrng', split);
            }
              
            fr.readAsText(this.files[0]);
            document.getElementById('inputfile').value = null;

        })

        document.getElementById('inputfile2')
            .addEventListener('change', function() {

            if(document.getElementById('inputfile2').value === null)
                return;

              
            var fr=new FileReader();

            fr.onload=function(){
                let res = fr.result;

                let split = res.split("\n");

                t.state = 'main';

                t.$emit('uploadquest', split);
            }
              
            fr.readAsText(this.files[0]);
            document.getElementById('inputfile2').value = null;

        })
    },
    makeActive() {
        this.$emit('makeActive', this.editingChar, false);
    },
    back() {
        this.edit = false;
        this.editingChar = null;
        this.state = 'main';
    },
    removeChar() {
        if(this.editingChar === null)
            return;

        this.$emit('removeChar', this.editingChar.id);

        this.state = 'main';
        this.resetInputs();
    },
    resetInputs() {
        document.getElementById('tokenid').value = "";
        document.getElementById('hp').value = "";
        document.getElementById('maxhp').value = "";
        document.getElementById('name').value = "";
        document.getElementById('override').value = "";
        document.getElementById('damage').value = "";
    },
    editChar(char) {
        this.editingChar = char;
        this.state = 'editchar'
        this.edit = true;
        

        document.getElementById('tokenid').value = char.tokenId;
        document.getElementById('hp').value = char.hp;
        document.getElementById('maxhp').value = char.maxHp;
        document.getElementById('name').value = char.name;
        document.getElementById('override').value = char.overrideImage;
        document.getElementById('damage').value = char.damage;
    },
    addChar() {


        let tokenId = document.getElementById('tokenid').value;
        let hp = document.getElementById('hp').value;
        let maxhp = document.getElementById('maxhp').value;
        let name = document.getElementById('name').value;
        let override = document.getElementById('override').value;
        let damage = document.getElementById('damage').value;

        if(this.edit) {
            this.editingChar.hp = hp;
            this.editingChar.maxHp = maxhp;
            this.editingChar.name = name;
            this.editingChar.overrideImage = override;
            this.editingChar.tokenId = tokenId;
            this.editingChar.damage = damage;


            this.edit = false;
            this.state = 'main';
            this.editingChar = null;
            return;

        }

        let charData = ({
            id: null,
            hp: hp,
            maxHp: maxhp,
            tokenId: tokenId,
            name: name,
            overrideImage: override,
            damage: damage
        });

        if(this.state === 'left-char')
            this.$emit('createCharLeft', charData);

        if(this.state === 'right-char')
            this.$emit('createCharRight', charData);

        
    },
  },
  computed: {
    
  },
};
</script>

<style scoped>

.file-upload-c {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
}

.token-creator {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main-top-c {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    flex-direction: row;
}

.main-c {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main-control-container {
    position: relative;
    display: flex;
    width: 80%;
    height: 300px;
    border-radius: 20px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

</style>
