<template>
    <div class="burn-container">
        <img class="burn-img" src="../assets/U_bar_med.png"/>

        <label v-if="show" class="burner-wallet">{{burningWallet}}</label>
        <label v-if="show" class="burner-id">#{{idBurning}}</label>
        

        <Fire
            class="fire"
            :burning="burning"
            :idBurning="idBurning"
            ref="realfire"
        />

        <img v-show="show" :style="[{'clip' : clip}]" class="burnt-wagdie" :src="getImage()"/>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */

import Fire from './Fire.vue';

import metadata from '../metadata.js';

export default {
  props: ['burning', 'idBurning', 'burningWallet'],
  data() {
    return {
      clip: `rect(0px, 180px, 180px, 0px)`,
      moveUp: 0,
      interval: null,
      show: false,
      disabled: false,
    };
  },
  components: {
      Fire
  },
  methods: {
    getImage() {

        let id = this.idBurning; 
        
        if(id === 0) {
            this.show = false;
            return;
        }


        let m = metadata.metadata[id-1].image;
        let imageHash = m.split("/")[2];
        let url = "https://ipfs.io/ipfs/" + imageHash


        return url;
    },
      startBurning(at) {

        if(this.disabled)
            return;

        clearInterval(this.interval);
        
        this.show = true;

        this.$refs.realfire.startFire(at);
        this.moveUp = at;

        this.interval = setInterval(() => {

            this.moveUp += 8;

            if(this.moveUp > 750) {
                clearInterval(this.interval);
                this.show = false;
                this.moveUp = 0;
                this.$emit('decrementToBurn')
            }

            let max = 750;
            let difference = max - this.moveUp;

            let perc = (difference / max);

            let x = 190 * perc;

            this.clip = `rect(0px, 180px, ${x}px, 0px)`


    }, 100);
      }
  },
  computed: {
    
  },
  mounted() {
    document.addEventListener("visibilitychange", (event) => {
        if (document.visibilityState !== "visible") {
            clearInterval(this.interval);
            this.disabled = true;
        } else {
            
            this.disabled = false;

            if(this.idBurning === 0)
                return;

            this.startBurning(this.moveUp);
        }
    });
  },
};
</script>

<style scoped>

.burner-id {
    position: absolute;
    bottom: 10px;
}

.burner-wallet {
    position: absolute;
    top: 10px;
}

.burn-img {
    position: absolute;
    display: flex;
    height: 300px;
    width: 300px;
    -webkit-user-drag: none;
    z-index: -3;
    filter: hue-rotate(150deg);
}

.fire {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
}

.burnt-wagdie {
    position: absolute;
    display: flex;
    width: 180px;
    height: 180px;
    z-index: -2;
    border-radius: 20px;
}

.burn-container {
    position: relative;
    display: flex;
    height: 300px;
    width: 300px;
    justify-content: center;
    align-items: center;
}

</style>
