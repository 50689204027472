<template>
    <div class="main-auth-container">

        <ButtonType
            :text="'Login to Discord'"
            :type="'long'"
            :size="2"
            :style="{'margin' : '10px'}"
            @click="authDiscord"
        />

    </div>
</template>

<script>

import ButtonType from './ButtonType.vue';

export default {
  data() {
    return {
      
    };
  },
  components: {
      ButtonType
  },
  methods: {
    authDiscord() {
        window.location.href = "https://discord.com/api/oauth2/authorize?client_id=1025571377933201468&redirect_uri=https%3A%2F%2Fwww.wagdie.net&response_type=token&scope=identify";
    }
  },
  computed: {
    
  },
};
</script>

<style scoped>

.main-auth-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}


</style>
