<template>
    <div class="main-leaderboard-container">

        <ButtonType
            :text="'Back'"
            :type="'med'"
            :size="1.5"
            :style="{'margin' : '10px'}"
            @click="$emit('back')"
        />


        <div class="main-p">
            

        <div class="death-container">

            <div class="menu-container">
                <ButtonType
                    v-if="leaderboardPage === 'recent'"
                    :text="'Leaderboard'"
                    :type="'long'"
                    :size="2"
                    :style="{'margin' : '10px'}"
                    @click="setPage('leaderboard')"
                />

                <ButtonType
                    v-if="leaderboardPage === 'leaderboard'"
                    :text="'Recent'"
                    :type="'long'"
                    :size="2"
                    :style="{'margin' : '10px'}"
                    @click="setPage('recent')"
                />

                
            </div>
            <label class="burn-count"> Burnt Souls: {{total}} </label>

        <div class="l-c" v-if="leaderboardPage === 'recent'">
            <div  v-for="soul in souls" :key="soul" class="soul-container">
            <label @click="openInNewTab(`https://opensea.io/assets/ethereum/0x659a4bdaaacc62d2bd9cb18225d9c89b5b697a5a/` + soul.id)" class="soul-id">#{{soul.id}}</label>
            <img class="soul-image" :src="getImage(soul.id)">
            <div class="burn-info">
                <label @click="openInNewTab(`https://etherscan.io/tx/` + soul.hash)" class="soul-time">Died {{timeSince(soul.timestamp)}}</label>
                <label @click="openInNewTab(`https://etherscan.io/tx/` + soul.hash)" class="soul-burner">Burnt by {{fixAddress(soul.burner)}}</label>
            </div>
            </div>
        </div>
            

        <div class="l-c" v-if="leaderboardPage === 'leaderboard'">
            <div  v-for="(burner, index) in burners" :key="burner" class="soul-container">
              <label class="soul-id">#{{(index + 1) + (page * 6)}}</label>
              <img class="soul-image" src="https://static.vecteezy.com/system/resources/previews/001/188/566/non_2x/fire-png.png">
              <div class="burn-info">
                <label @click="openInNewTab(`https://etherscan.io/address/` + burner.id)" class="soul-time">{{burner.amountburnt}} Burns</label>
                <label @click="openInNewTab(`https://etherscan.io/address/` + burner.id)" class="soul-burner">{{fixAddress(burner.id)}}</label>
              </div>
            </div>
        </div>
            

        </div>

        Page {{page + 1}}
        <div class="buttons">
          <div @click="$emit('changePage', -1)" class="b">Prev</div>
          <div @click="$emit('changePage', 1)" class="b">Next</div>
        </div>

    </div>

    

    </div>
</template>

<script>

import ButtonType from './ButtonType.vue';
import metadata from '../metadata.js';
import Web3 from 'web3';

export default {
  props: ['souls', 'page', 'total', 'burners'],
  data() {
    return {
      leaderboardPage: "recent",
    };
  },
  components: {
      ButtonType
  },
  methods: {
    setPage(page) {
        this.leaderboardPage = page;

        this.$emit('changePage', 500);
    },
    fixAddress(address) {
      let isAddress = Web3.utils.isAddress(address);

      if(isAddress)
        return this.minifyAddress(address);

      return address;
    },
    openInNewTab(url) {
      window.open(url, '_blank').focus();
    },
    minifyAddress(address) {
          return address.substring(0, 5) + "..." + address.substring(address.length - 4, address.length);
      },
    getImage(id) {
      let m = metadata.metadata[id-1].image;
      let imageHash = m.split("/")[2];
      let url = "https://ipfs.io/ipfs/" + imageHash


      return url;
    },
    timeSince(timestamp) {
      let timeNow = Math.floor(Date.now()/1000);

      let text = "";

      let seconds = timeNow - timestamp;

      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      if(seconds > 1)
          text = seconds + " Seconds ago"

      if(seconds == 1)
          text = seconds + " Second ago"

      if(minutes > 1)
          text = minutes + " Minutes ago"

      if(minutes == 1)
          text = minutes + " Minute ago"

      if(hours >= 1)
          text = hours + " Hours ago";

      if(hours == 1)
          text = "1 Hour ago";

      if(days >= 1)
          text = days + " Days ago";

      if(days == 1)
          text = "1 Day ago";


      return text;
    },
  },
  computed: {
    
  },
};
</script>

<style scoped>

.l-c {
    width: 100%;
}

.main-leaderboard-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.burn-info {
  position: relative;
  display: flex;
  flex-direction: column;
}

.burn-info:hover {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #b1b1b1;
}

.soul-burner {
  cursor: pointer;
}

.buttons {
  position: relative;
  display: flex;

}

.burn-address {
  font-size: 20px;
}

.burn-warning {
  font-size: 18px;
  margin-top: 10px;
}

.main-p {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 50px;
    flex-direction: column;
    overflow-y: auto;
}

.soul-image {
  position: relative;
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.soul-id {
  position: relative;
  display: flex;
  margin-right: 20px;
  font-size: 30px;
  cursor: pointer;
}

.soul-id:hover {
  position: relative;
  display: flex;
  margin-right: 20px;
  font-size: 30px;
  color: #b1b1b1;
  cursor: pointer;
}

.soul-time {
  position: relative;
  display: flex;
  margin-left: 20px;
  font-size: 30px;
  cursor: pointer;
}

.b {
  font-size: 40px;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 30px;
  user-select: none;
  cursor: pointer;
}

.soul-container {
  position: relative;
  display: flex;
  width: 90%;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.burn-count {
  font-size: 25px;
  margin-bottom: 40px;
}

.death-container {
  position: relative;
  display: flex;
  height: 800px;
  width: 420px;
  margin-bottom: 50px;
  flex-direction: column;
  align-items: center;
}

</style>
