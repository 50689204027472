<template>
    <div class="menu-container">
        <img class="back" src="../assets/U_bar_med.png"/>
        <img class="main-img" :src="require(`../assets/${image}`)"
            :style="name === 'Souls' ? {'border-radius' : '20px'} : {'border-radius' : '0px'}"
        />

        <label class="name">{{name}}</label>

    </div>
</template>

<script>


export default {
  props: ['name', 'image'],  
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  computed: {
    
  },
};
</script>

<style scoped>

.name {
    position: absolute;
    bottom: 10px;
    cursor: pointer;  
}

.back {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: -1;
    cursor: pointer;  
    -webkit-user-drag: none;
    filter: hue-rotate(150deg);
}

.main-img {
    position: absolute;
    display: flex;
    width: 70%;
    height: 70%;
    margin-top: 10px;
    cursor: pointer;  
    user-select: none;
    -webkit-user-drag: none;
}

.menu-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 150px;
    width: 150px;
    cursor: pointer;
    user-select: none;
    margin: 20px;
    border-radius: 7px;
}

.menu-container:hover {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 150px;
    width: 150px;
    cursor: pointer;
    user-select: none;
    margin: 20px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.26);
}

</style>
