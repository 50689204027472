<template>
    <div class="main-souls-container">

        <ButtonType
            :text="'Back'"
            :type="'med'"
            :size="1.5"
            :style="{'margin' : '10px'}"
            @click="$emit('back')"
        />

        <div class="info-container">
            <label>If you have burnt a wagdie you may mint the same token as a dead version.</label>
        </div>

        <div v-if="!loadedSouls" class="info-container">
            <label>Loading, please wait..</label>
        </div>

        <div v-if="loadedSouls && burntSouls.length === 0" class="info-container">
            <label>You have no unclaimed burnt souls available</label>
        </div>


        <div class="souls-container">

            <div v-for="(soul) in burntSouls" :key="soul" class="soul">
                <img class="soul-img" :src="getImage(soul.id)"/>

                <label>#{{soul.id}}</label>

                <ButtonType
                    :text="'Mint'"
                    :type="'med'"
                    :size="1.5"
                    :style="{'margin' : '10px'}"
                    @click="$emit('mintSoul', soul.id)"
                />
            </div>

            

        </div>

    </div>
</template>

<script>

import ButtonType from './ButtonType.vue';

import metadata from '../metadata.js';

export default {
  props: ['burntSouls', 'loadedSouls'],  
  data() {
    return {
      
    };
  },
  components: {
      ButtonType
  },
  methods: {
    getImage(id) {



        let m = metadata.metadata[id-1].image;
        let imageHash = m.split("/")[2];
        let url = "https://ipfs.io/ipfs/" + imageHash


        return url;
    },
  },
  computed: {
    
  },
};
</script>

<style scoped>

.soul {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 175px;
    flex-direction: column;
    margin: 20px;
}

.soul-img {
    width: 125px;
    height: 125px;
    -webkit-user-drag: none;
    border-radius: 20px;
}

.main-souls-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.souls-container {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
    margin-top: 75px;
    flex-wrap: wrap;
}

.info-container {
    margin-top: 20px;
    font-size: 20px;
    width: 75%;
}

</style>
